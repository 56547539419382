<template>
  <!-- <b-modal size="lg" v-model="showModal" @hide="onHide" title="Edit Card Carousel" ok-only no-enforce-focus> -->
  <el-dialog top="10vh" title="Edit Card Carousel" @close="onHide" :visible.sync="showModal" :close-on-press-escape="false" :close-on-click-modal="false">
    <hr style="margin-top:-15px" />
    <div id="card-content" class="ml-2 mr-2">
      <div id="body-content">
        <div class="mb-5">
          <div>
            <el-radio-group v-model="active_template_show.header.content">
              <el-radio-button border v-for="item in header_media_type" :key="item.type" :label="item.type" :class="active_template_show.header.content==item.label">
                <font-awesome :icon="item.icon"/>&nbsp; {{ item.label }}
              </el-radio-button>
            </el-radio-group>
            <h6 class="text-capitalize">Media</h6>
            <b-file v-loading="loading.upload_sample_media" class="mt-2" :accept="whiteListExtensionHeaderMedia[active_template_show.header.content].join(',')" @input="handlerFileHeaderMedia()" v-model="active_template_show.header.file"/>
            <small class="text-primary" v-if="active_template_show.header.file">File Choosen</small>
            <hr/>
          </div>
          <h6 class="text-capitalize">Body</h6>
          <b-card-sub-title class="mb-2">Enter the text for your message in the language you've selected.</b-card-sub-title>
          <el-input type="textarea"
            :placeholder="'Input text in ' + language_str"
            :trigger-on-focus="false"
            v-model="active_template_show.body.content"
            maxlength="160"
            ref="inputBodyContentCarousel"
            @focus="() => last_index_emoji = null"
            @keydown.native="handlerInputBodyContentCarousel"
            @keyup.native="addSampleContent()"
            show-word-limit
            rows="6"/>
          <div class="float-right mt-2 mb-2">
            <el-popover
              placement="top"
              width="380"
              trigger="hover"
            >
              <picker @select="addEmoji" size="16" :showPreview="false" />
              <span slot="reference">
                <FeatherIcon type="smile" class="mr-2" style="width: 16px; margin-top: 5px; margin-bottom: -5px;"/>
              </span>
            </el-popover>
            <el-button size="small" type="info"
              @click="addVariableBodyCarousel"><font-awesome icon="plus"/>
              &nbsp; Add Variable
            </el-button>&nbsp;
            <el-popover
              placement="right"
              width="320"
              trigger="hover">
              <p style="white-space: pre-line; overflow-wrap: break-word; word-break: break-all;">This is text you specify in the API that will be personalized to the customer, such as their name or invoice number.</p>
              <span slot="reference"><font-awesome icon="info-circle"/></span>
            </el-popover>
          </div>
        </div>
        <div class="mb-2 row" v-if="bodyHasParam">
          <span class="col-md-6" v-for="(item, i) in active_template_show.body.examples" :key="i">
            <el-input class="mt-1" size="small" v-model="active_template_show.body.examples[i].text" :placeholder="`Input content for ` + item.replacement"/>
          </span>
        </div>
      </div>
      <hr/>
      <!-- <div id="footer-content">
        <b-card-title class="text-capitalize">Footer (optional)</b-card-title>
        <b-card-sub-title class="mb-2">Add a short line of text to the bottom of your message template.</b-card-sub-title>
        <el-input
          type="text"
          :placeholder="'Input text in ' + active_template_show.language_str"
          v-model="active_template_show.footer.content"
          maxlength="60"
          show-word-limit
        />
      </div>
      <hr/> -->
      <div id="buttons-content">
        <h6 class="text-capitalize">Buttons</h6>
        <b-card-sub-title class="mb-2">Create buttons that let customers respond to your message or take action.</b-card-sub-title>
        <!-- <el-select @change="handlerChangeButtonsParent" v-model="active_template_show.buttons.parent_type">
          <el-option v-for="(item, i) in footer_category" :key="i" :label="item.label" :value="item.type"/>
        </el-select> -->
        <!-- <div v-if="active_template_show.buttons.parent_type === 'quick_reply'">
          <div class="inline inline-flex align-middle"
            v-for="(item, i) in active_template_show.buttons.content" :key="i">
            <el-input v-model="active_template_show.buttons.content[i].text"
              class="mt-2 w-50 mr-2"
              maxlength="20"
              show-word-limit/>
            <span v-if="i > 0">
              <a @click="handlerRemoveQuickReply(i)" class="cursor-pointer"><font-awesome class="text-danger" style="font-size: 1.5em" icon="times-circle"/></a>
            </span>
          </div>
          <el-button
            @click="addButtonQuickReply"
            :disabled="active_template_show.buttons.content.length === max_length.quick_reply"
            type="info"
            size="small"
            class="mt-2"><font-awesome icon="plus"/>&nbsp; Add Another Button
          </el-button>
        </div> -->
        <!-- <div v-if="active_template_show.buttons.parent_type === 'call_to_action'" class="mt-2"> -->
        <div class="table-responsive mt-2">
          <b-row
            v-for="(item, i) in active_template_show.buttons.content" :key="i">
            <b-col md=3>
              <b-form-group
                label="Type of Action">
                <el-select v-model="active_template_show.buttons.content[i].type">
                  <el-option
                    v-for="item in call_to_action_category"
                    :key="item.type"
                    :value="item.type"
                    :label="item.label"/>
                </el-select>
              </b-form-group>
            </b-col>
            <b-col :md="active_template_show.buttons.content[i].type == 'quick_reply' && active_template_show.buttons.content.length > 1 ? '4':'3'">
              <b-form-group
                label="Button Text">
                <el-input v-model="active_template_show.buttons.content[i].text"
                  maxlength="20"
                  :class="{ 'w-80': active_template_show.buttons.content[i].type == 'quick_reply' && active_template_show.buttons.content.length > 1 }"
                  show-word-limit/>
                <span v-if="active_template_show.buttons.content[i].type == 'quick_reply' && active_template_show.buttons.content.length > 1">
                  &nbsp;<a @click="handlerRemoveCTA(i)" class="cursor-pointer"><font-awesome class="text-danger" style="font-size: 1em" icon="times-circle"/></a>
                </span>
              </b-form-group>
            </b-col>
            <!-- Website Button -->
            <b-col md=2 v-if="active_template_show.buttons.content[i].type === 'url'">
              <b-form-group
                label="URL Type">
                <el-select v-model="active_template_show.buttons.content[i].url_type" @change="handlerChangeButtonType(i)">
                  <el-option v-for="item in url_type" :key="item.type" :value="item.type" :label="item.label"/>
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md=4 v-if="active_template_show.buttons.content[i].type === 'url'">
              <b-form-group style="width: calc(250%)"
                label="Website URL">
                <div class="row">
                  <div class="col-md-7">
                    <el-input v-model="active_template_show.buttons.content[i].url"
                      :class="{ 'w-80': active_template_show.buttons.content.length > 1 && (item.type != 'url' || item.url_type != 'dynamic') }"
                      maxlength="2000"
                      show-word-limit>
                      <template v-if="active_template_show.buttons.content[i].url_type == 'dynamic'" slot="append">
                        <span v-pre>{{1}}</span>
                      </template>
                    </el-input>
                    <span v-if="active_template_show.buttons.content.length > 1 && (item.type != 'url' || item.url_type != 'dynamic')">
                      &nbsp;<a @click="handlerRemoveCTA(i)" class="cursor-pointer"><font-awesome class="text-danger" style="font-size: 1em" icon="times-circle"/></a>
                    </span>
                  </div>
                  <div class="col-md-4"
                    v-if="item.type == 'url' && item.url_type == 'dynamic'">
                    <el-input v-model="active_template_show.buttons.examples[i]"
                      :class="{ 'w-80': active_template_show.buttons.content.length > 1 }"
                      placeholder="Input full URL" />
                    <span v-if="active_template_show.buttons.content.length > 1">
                      &nbsp;<a @click="handlerRemoveCTA(i)" class="cursor-pointer"><font-awesome class="text-danger" style="font-size: 1em" icon="times-circle"/></a>
                    </span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <!-- End Of Website Button -->
            <!-- Phone Number Button -->
            <b-col md=2 v-if="active_template_show.buttons.content[i].type === 'phone_number'">
              <b-form-group
                label="Country">
                <el-select v-model="active_template_show.buttons.content[i].country_code">
                  <el-option v-for="item in country_code" :key="item.value" :value="item.value" :label="item.label_str"/>
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md=4 v-if="active_template_show.buttons.content[i].type === 'phone_number'">
              <b-form-group>
                <span slot="label">Phone Number&nbsp; <el-popover
                    placement="right"
                    width="200"
                    trigger="hover">
                    <p style="white-space: pre-line; overflow-wrap: break-word; word-break: break-all;">Input phone number<br/>without country code.</p>
                    <span slot="reference"><font-awesome icon="info-circle"/></span>
                  </el-popover>
                </span>
                <el-input v-model="active_template_show.buttons.content[i].phone_number"
                  maxlength="20"
                  :class="{ 'w-80': active_template_show.buttons.content.length > 1 }"
                  show-word-limit/>
                <span v-if="active_template_show.buttons.content.length > 1">
                  &nbsp;<a @click="handlerRemoveCTA(i)" class="cursor-pointer"><font-awesome class="text-danger" style="font-size: 1em" icon="times-circle"/></a>
                </span>
              </b-form-group>
            </b-col>
            <!-- End Of Phone Number Button -->
          </b-row>
          <el-button
            @click="addButtonCTA"
            :disabled="active_template_show.buttons.content.length === max_length.quick_reply"
            type="info"
            size="small"
            class="mt-2"><font-awesome icon="plus"/>&nbsp; Add Another Button
          </el-button>
        </div>
        <!-- <h6 class="text-capitalize mt-3" v-if="buttonHasDynamic && active_template_show.buttons.content.length">Buttons</h6>
        <div v-if="buttonHasDynamic && active_template_show.buttons.content.length" class="row">
          <span class="col-md-12" v-for="(item, i) in active_template_show.buttons.content" :key="i">
            <el-input
              size="small"
              class="mt-1"
              type="text"
              v-model="active_template_show.buttons.examples[i]"
              v-if="item.type == 'url' && item.url_type == 'dynamic'"
              :placeholder="`Input full URL for ` + item.text + `: ` + item.url" />
          </span>
        </div> -->
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <hr style="margin-top:-15px" />
      <el-button type="primary" @click="onHide()">Oke</el-button>
    </span>
  </el-dialog>
  <!-- </b-modal> -->
</template>

<script>
import moment from 'moment';
import { cloneDeep, isEmpty, escapeRegExp } from 'lodash';
import language from '@/library/language.json';
import country_code from '@/library/country_code.json';
import popupErrorMessages from '@/library/popup-error-messages';
import { Picker } from 'emoji-mart-vue-2';
import wa_auth_wording from '@/library/wa_auth_wording.json';
// import { Swiper, SwiperSlide } from 'swiper-vue2';
// import 'swiper/swiper-bundle.css';
import notifApi from '../../../api/notifications';
import base64File from '../../../library/base64File';
import divisions from '../../../api/divisions';

export default {
  name: 'Carousel',
  components: {
    Picker,
    // Swiper,
    // SwiperSlide,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    language_str: {
      type: String,
      default: '',
    },
    active_template_show: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      showModal: false,
      moment,
      step: 1,
      loading: {
        upload_sample_media: false,
        submit: false,
      },
      isShowModal: {
        editTemplateName: false,
        addSample: false,
        carouselCard: false,
      },
      max_length: {
        quick_reply: 2,
      },
      temp: {
        selected_language: [],
        template_name: '',
        groups_id: [],
      },
      template_category: {
        whatsapp: [
          {
            name: 'Marketing',
            key: 'marketing',
            icon: 'bullhorn',
            info: 'Send promotions or information about your products, services, or business.',
          },
          {
            name: 'Utility',
            key: 'utility',
            icon: 'bell',
            info: 'Send messages about an existing order or account.',
          },
          {
            name: 'Authentication',
            key: 'authentication',
            icon: 'key',
            info: 'Send codes to verify a transaction or login.',
          },
        ],
      },
      bg_media: {
        image: 'https://media.wappin.chat/template-header-image.png',
        document: 'https://media.wappin.chat/template-header-document.png',
        video: 'https://media.wappin.chat/template-header-video.png',
      },
      form1: {
        category: '',
        template_name: '',
      },
      template_form: [],
      otp_delivery_options: [
        // {
        //   title: 'Auto-fill',
        //   description: 'Recommended as the easiest option for your customers. The code is sent to your app when a customer taps the button. A copy code message will be sent when auto-fill isn\'t possible.',
        //   value: 'one_tap',
        // },
        {
          title: 'Copy Code',
          description: 'Basic authentication with quick setup. Your customers copy and paste the code into your app.',
          value: 'copy_code',
        },
      ],
      languages_list: language,
      header_category: [
        {
          type: 'none',
          label: 'None',
        },
        {
          type: 'text',
          label: 'Text',
        },
        {
          type: 'media',
          label: 'Media',
        },
        {
          type: 'carousel',
          label: 'Carousel',
        },
      ],
      header_media_type: [
        {
          type: 'image',
          label: 'Image',
          icon: 'image',
        },
        {
          type: 'video',
          label: 'Video',
          icon: 'video',
        },
      ],
      footer_category: [
        {
          type: 'none',
          label: 'None',
        },
        {
          type: 'call_to_action',
          label: 'Call To Action',
        },
        {
          type: 'quick_reply',
          label: 'Quick Reply',
        },
      ],
      call_to_action_category: [
        {
          type: 'url',
          label: 'Visit Website',
        },
        {
          type: 'phone_number',
          label: 'Call Phone Number',
        },
        {
          type: 'quick_reply',
          label: 'Quick Reply',
          group: 'quick_reply',
        },
      ],
      url_type: [
        {
          type: 'static',
          label: 'Static',
        },
        {
          type: 'dynamic',
          label: 'Dynamic',
        },
      ],
      country_code: country_code.map((v) => {
        v.label_str = `(${v.value}) ${v.label}`;
        return v;
      }),
      addMoreLanguage: '',
      whiteListExtensionHeaderMedia: {
        image: ['.jpg', '.png'],
        video: ['.mp4'],
        document: ['.pdf'],
      },
      division_list: [],
      last_index_emoji: null,
      wa_auth_wording,
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    activeChannel() {
      return this.$store.state.channels.activeChannel;
    },
    isValidStep1() {
      return this.form1.category !== '' && this.form1.template_name !== '' && this.temp.selected_language.length > 0 && this.temp.groups_id.length > 0;
    },
    isMaxVariableHeader() {
      if (!this.active_template_show.header.content) return false;
      const match_regex = this.active_template_show.header.content.match(/({{\d{1,}}})/ig);
      return this.active_template_show.header.content && match_regex && match_regex.length > 0;
    },
    previewHeaderContent() {
      if (this.active_template_show.header.type === 'text') {
        if (this.active_template_show.header.example.data) {
          return this.active_template_show.header.content.replace('{{1}}', this.active_template_show.header.example.data);
        }
        return this.active_template_show.header.content;
      }
      return '';
    },
    previewFooterContent() {
      return this.active_template_show.footer.content;
    },
    previewContent() {
      let string = this.active_template_show.body.content;
      this.active_template_show.body.examples.forEach((v) => {
        if (v.text) {
          const replaceRegex = new RegExp(escapeRegExp(v.replacement), 'ig');
          string = string.replace(replaceRegex, v.text);
        }
      });
      const htmlFormat = [
        { symbol: '*', tag: 'b' },
        { symbol: '_', tag: 'em' },
        { symbol: '~', tag: 'del' },
        { symbol: '```', tag: 'tt' },
        { symbol: '`', tag: 'mark' },
      ];

      htmlFormat.forEach(({ symbol, tag }) => {
        if (!string) return;
        const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, 'gm');
        const match = string.match(regex);
        if (!match) return;
        match.forEach((m) => {
          let formatted = m;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < 2; i++) {
            formatted = formatted.replace(symbol, `<${i > 0 ? '/' : ''}${tag}>`);
          }
          string = string.replace(m, formatted);
        });
      });
      return string;
    },
    previewContentCarousel() {
      let string = this.active_template_show.body.content;
      this.active_template_show.body.examples.forEach((v) => {
        if (v.text) {
          const replaceRegex = new RegExp(escapeRegExp(v.replacement), 'ig');
          string = string.replace(replaceRegex, v.text);
        }
      });
      const htmlFormat = [
        { symbol: '*', tag: 'b' },
        { symbol: '_', tag: 'em' },
        { symbol: '~', tag: 'del' },
        { symbol: '```', tag: 'tt' },
        { symbol: '`', tag: 'mark' },
      ];

      htmlFormat.forEach(({ symbol, tag }) => {
        if (!string) return;
        const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, 'gm');
        const match = string.match(regex);
        if (!match) return;
        match.forEach((m) => {
          let formatted = m;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < 2; i++) {
            formatted = formatted.replace(symbol, `<${i > 0 ? '/' : ''}${tag}>`);
          }
          string = string.replace(m, formatted);
        });
      });
      return string;
    },
    bodyHasParam() {
      if (!this.active_template_show.body) {
        return null;
      }
      return this.active_template_show.body.content.match(/({{\d{1,}}})/ig);
    },
    buttonHasDynamic() {
      if (!this.active_template_show.buttons) {
        return false;
      }
      if (this.active_template_show.category === 'authentication') {
        return true;
      }
      let has_dynamic = false;
      const url_btn = this.active_template_show.buttons.content.filter((v) => v.type === 'url' && v.url_type === 'dynamic');
      if (url_btn.length > 0) {
        has_dynamic = true;
      }
      return has_dynamic;
    },
    isValidExample() {
      if (!this.active_template_show.body) {
        return false;
      }
      const empty_body_example = this.active_template_show.body.examples.find((v) => !v.text);
      return !isEmpty(empty_body_example);
    },
    isCompleteSample() {
      if (!this.active_template_show.body) {
        return false;
      }
      if (this.bodyHasParam) {
        if (this.active_template_show.body && this.active_template_show.body.examples.length === 0) {
          return false;
        }
        if (this.bodyHasParam.length > 0 && this.bodyHasParam.length !== this.active_template_show.body.examples.length) {
          return false;
        }
      }
      const empty_body_example = this.active_template_show.body.examples.find((v) => !v.text);
      return isEmpty(empty_body_example);
    },
  },
  watch: {
    show() {
      this.showModal = this.show;
      if (this.showModal) {
        this.getListDivisions();
      }
    },
  },
  methods: {
    handlerChangeButtonType(idx) {
      if (this.active_template_show.buttons.content[idx].url_type === 'static') {
        this.active_template_show.buttons.examples[idx] = '';
      }
    },
    onHide() {
      this.$emit('onHide');
    },
    setSelectedCategory(item) {
      this.form1.category = item.key;
    },
    setActiveTemplate(lang) {
      this.template_form = this.template_form.map((v) => {
        if (v.language === this.active_template_show.language) {
          return this.active_template;
        }
        return v;
      });
      this.active_template = this.template_form.find((v) => v.language === lang);
    },
    handlerInputTemplateName(event, target) {
      if (target === 'edit_template_name') {
        this.temp.template_name = this.temp.template_name.replace(/\s/g, '_');
        this.temp.template_name = this.temp.template_name.toLowerCase();
      } else if (target === 'create_template_name') {
        this.form1.template_name = this.form1.template_name.replace(/\s/g, '_');
        this.form1.template_name = this.form1.template_name.toLowerCase();
      }
    },
    showModalEditTemplateName() {
      this.temp.template_name = this.form1.template_name;
      this.isShowModal.editTemplateName = true;
    },
    onSubmitEditTemplateName() {
      this.form1.template_name = this.temp.template_name;
      this.temp.template_name = '';
      this.isShowModal.editTemplateName = false;
    },
    closeModalEditTemplate() {
      this.temp.template_name = '';
      this.isShowModal.editTemplateName = false;
    },
    addVariableBodyCarousel() {
      const total_variable = this.active_template_show.body.content.match(/({{\d{1,}}})/ig);
      this.active_template_show.body.content += `${this.active_template_show.body.content.endsWith(' ') ? '' : ' '}{{${total_variable ? total_variable.length + 1 : 1}}}`;
      this.$refs.inputBodyContentCarousel.focus();
      this.addSampleContent();
    },
    handlerInputBodyContentCarousel(event) {
      // console.log(event);
      // const position = event.target.selectionStart;
      // this.active_template_show.body.content = event.target.value;
      // console.log(position);
      const { keyCode, shiftKey } = event;
      if (keyCode === 219 && shiftKey) {
        if (this.active_template_show.body.content.endsWith('{')) {
          const total_variable = this.active_template_show.body.content.match(/({{\d{1,}}})/ig);
          this.active_template_show.body.content += `{${total_variable ? total_variable.length + 1 : 1}}}`;
          this.$refs.inputBodyContentCarousel.focus();
          event.preventDefault();
        }
      }
    },
    handlerChangeButtonsParent(value) {
      if (value === 'quick_reply') {
        this.active_template_show.buttons.content = [
          {
            type: 'quick_reply',
            text: '',
          },
        ];
      } else if (value === 'call_to_action') {
        this.active_template_show.buttons.content = [
          {
            type: 'url',
            url_type: 'static',
            text: '',
            url: '',
          },
        ];
      }
    },
    addButtonQuickReply() {
      this.active_template_show.buttons.content.push({
        type: 'quick_reply',
        text: '',
      });
    },
    handlerRemoveQuickReply(index) {
      this.active_template_show.buttons.content.splice(index, 1);
    },
    addButtonCTA() {
      this.active_template_show.buttons.content.push({
        type: 'url',
        url_type: '',
        text: '',
        url: '',
        phone_number: undefined,
        country_code: undefined,
      });
    },
    handlerRemoveCTA(index) {
      this.active_template_show.buttons.content.splice(index, 1);
    },
    handlerRemoveLanguageContent(selected_template) {
      const index = this.template_form.findIndex((v) => v.language === selected_template.language);
      this.active_template = this.template_form[(index > 0 ? index - 1 : index + 1)];
      this.temp.selected_language.splice(this.temp.selected_language.findIndex((v) => v === selected_template.language), 1);
      this.template_form.splice(index, 1);
    },
    handlerAddMoreLanguage(value) {
      const obj = {
        template_name: this.form1.template_name,
        category: this.form1.category,
        language_str: this.languages_list.find((lg) => lg.value === value).label,
        language: value,
        body: {
          content: '',
          examples: [],
        },
        header: {
          type: 'none',
          content: '',
          example: { data: '' },
        },
        footer: {
          type: '',
          examples: [],
        },
        buttons: {
          parent_type: 'none',
          content: [],
          examples: [],
        },
      };
      this.temp.selected_language.push(value);
      this.template_form.push(obj);
      this.addMoreLanguage = '';
    },
    addVariableHeader() {
      this.active_template_show.header.content += '{{1}}';
    },
    handlerInputHeaderContent(event) {
      const { keyCode, shiftKey } = event;
      if (keyCode === 219 && shiftKey) {
        if (this.active_template_show.header.content.endsWith('{')) {
          if (!this.active_template_show.header.content) {
            this.active_template_show.header.content = '';
          }
          const total_variable = this.active_template_show.header.content.match(/({{\d{1,}}})/ig);
          if (!total_variable || !total_variable.length) {
            this.active_template_show.header.content += `{${total_variable ? total_variable.length + 1 : 1}}}`;
            this.$refs.inputHeaderContent.focus();
            event.preventDefault();
          }
        }
      }
    },
    async submitTemplate() {
      // console.log(this.template_form);
      this.loading.submit = true;
      const response = await notifApi.submitRequestTemplate({
        workspace_id: this.activeWorkspace._id,
        channel_id: this.activeChannel._id,
        templates: this.template_form,
        groups_id: this.temp.groups_id,
      });
      this.loading.submit = false;
      await popupErrorMessages(response);
      this.$message({
        message: this.$t('templates.success.request_template'),
        type: 'success',
      });
      this.$router.push({
        name: 'Broadcast Templates WhatsApp',
      });
    },
    addSampleContent() {
      if (this.bodyHasParam) {
        this.bodyHasParam.forEach((v) => {
          if (!this.active_template_show.body.examples.find((vk) => vk.replacement === v)) {
            this.active_template_show.body.examples.push({ replacement: v, text: '' });
          }
        });
        if (this.bodyHasParam && this.active_template_show.body.examples.length > this.bodyHasParam.length) {
          this.active_template_show.body.examples.splice(this.bodyHasParam.length);
        }
      } else {
        this.active_template_show.body.examples = [];
      }
      this.active_template = cloneDeep(this.active_template);
    },
    showCarouselCard(index) {
      console.log(index);
      this.isShowModal.carouselCard = true;
    },
    submitChangeTemplateName() {
      this.active_template_show.template_name = this.temp.template_name;
      this.template_form = this.template_form.map((v) => {
        v.template_name = this.temp.template_name;
        return v;
      });
      this.form1.template_name = this.temp.template_name;
      this.isShowModal.editTemplateName = false;
    },
    handlerChangeHeaderType() {
      this.active_template_show.header.example = {
        data: '',
        base64: '',
      };
      if (this.active_template_show.header.type === 'carousel') {
        this.active_template_show.header.content = 'image';
      } else {
        this.active_template_show.header.content = '';
      }
    },
    async handlerFileHeaderMedia() {
      if (this.active_template_show.header.file) {
        this.loading.upload_sample_media = true;
        const formData = new FormData();
        formData.append('file', this.active_template_show.header.file);
        const response = await notifApi.uploadTemplateMedia(formData, this.activeWorkspace._id, this.activeChannel._id);
        const { error } = response;
        this.loading.upload_sample_media = false;
        if (error) {
          this.active_template_show.header.file = '';
          await popupErrorMessages(response);
        } else {
          this.active_template_show.header.example.data = response.data.h;
          this.active_template_show.header.example.base64 = await base64File(this.active_template_show.header.file);
        }
      }
    },
    async getListDivisions() {
      this.loading.table = true;
      const options = {
        workspace_id: this.activeWorkspace._id,
        channel_type: 'whatsapp',
      };
      const response = await divisions.listForTemplates(options);
      this.loading.table = false;
      await popupErrorMessages(response);
      const { data } = response;
      this.division_list = data.rows.map((v) => {
        v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
        return v;
      });
      this.total = data.count;
    },
    addEmoji(emoji) {
      const pos = this.last_index_emoji !== null ? this.last_index_emoji : this.$refs.inputBodyContentCarousel.$refs.textarea.selectionStart;
      if (this.last_index_emoji === null) this.last_index_emoji = pos;
      const start_str = this.active_template_show.body.content.substring(0, pos);
      const end_str = this.active_template_show.body.content.substring(pos, this.active_template_show.body.content.length);
      this.active_template_show.body.content = [start_str, emoji.native, end_str].join('');
    },
    handlerOtpTypeRadio(type) {
      this.active_template_show.otp_delivery_type = type;
    },
    checkedSecurityChanged(value, lang) {
      if (value) {
        this.active_template_show.body.content += ` ${this.wa_auth_wording[lang].security_text}`;
      } else {
        this.active_template_show.body.content = this.active_template_show.body.content.replace(` ${this.wa_auth_wording[lang].security_text}`, '');
      }
    },
    handlerChangeOtpExpires() {
      this.checkedExpiryChanged(this.active_template_show.otp_add_expiry_wording, this.active_template_show.language);
    },
    checkedExpiryChanged(value, lang) {
      if (value) {
        this.active_template_show.footer = {
          content: this.wa_auth_wording[lang].footer_text.replace('{{expiry_time}}', this.active_template_show.otp_expires_in),
        };
      } else {
        this.active_template_show.footer = {
          type: '',
          examples: [],
        };
      }
    },
  },
};
</script>
<style>
.screen {
  text-align: left;
}
.screen-container {
  height: 100%;
}

/* Status Bar */

.status-bar {
  height: 25px;
  background: #004e45;
  color: #fff;
  font-size: 14px;
  padding: 0 8px;
}

.status-bar:after {
  content: "";
  display: table;
  clear: both;
}

.status-bar div {
  float: right;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 0 0 8px;
  font-weight: 600;
}

/* Chat */

.chat {
  height: calc(100% - 69px);
}

.chat-container {
  height: 100%;
}

/* User Bar */

.user-bar {
  height: 55px;
  background: #005e54;
  color: #fff;
  padding: 0 8px;
  font-size: 24px;
  position: relative;
  z-index: 1;
}

.user-bar:after {
  content: "";
  display: table;
  clear: both;
}

.user-bar div {
  float: left;
  transform: translateY(-50%);
  position: relative;
  top: 50%;
}

.user-bar .actions {
  float: right;
  margin: 0 0 0 20px;
}

.user-bar .actions.more {
  margin: 0 12px 0 32px;
}

.user-bar .actions.attachment {
  margin: 0 0 0 30px;
}

.user-bar .actions.attachment i {
  display: block;
  transform: rotate(-45deg);
}

.user-bar .avatar {
  margin: 0 0 0 5px;
  width: 36px;
  height: 36px;
}

.user-bar .avatar img {
  border-radius: 50%;
  /* box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1); */
  display: block;
  width: 100%;
}

.user-bar .name {
  font-size: 17px;
  font-weight: 600;
  text-overflow: ellipsis;
  letter-spacing: 0.3px;
  margin: 0 0 0 8px;
  overflow: hidden;
  white-space: nowrap;
  width: 110px;
}

.user-bar .status {
  display: block;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
}

/* Conversation */

.conversation {
  height: calc(100% - 12px);
  position: relative;
  background: #efe7dd url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg") repeat;
  z-index: 0;
}

.conversation ::-webkit-scrollbar {
  transition: all .5s;
  width: 5px;
  height: 1px;
  z-index: 10;
}

.conversation ::-webkit-scrollbar-track {
  background: transparent;
}

.conversation ::-webkit-scrollbar-thumb {
  background: #b3ada7;
}

.conversation .conversation-container {
  height: calc(100% - 68px);
  /* box-shadow: inset 0 10px 10px -10px #000000; */
  overflow-x: hidden;
  padding: 0 16px;
  margin-bottom: 5px;
}

.conversation .conversation-container:after {
  content: "";
  display: table;
  clear: both;
}

.button-area {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 12px;
  padding: 8px 0px;
  position: relative;
  word-wrap: break-word;
  z-index: -1;
  border-radius: 5px;
  float: left;
}

.reply-button {
  color: #5591EC;
  clear: both;
  line-height: 18px;
  font-size: 12px;
  padding: 8px;
  position: relative;
  word-wrap: break-word;
  z-index: -1;
  background: #fff;
  border-radius: 5px;
  float: left;
  text-align: center;
}

.cta-area {
  border-top: 1px solid #dadde1;
  /* margin: 0 7px 0 9px; */
  margin-top: 8px;
  float: right;
  background: #fff;
}

.cta-button {
  color: #00a5f4;
  font-size: 12px;
  height: 24px;
  line-height: 20px;
  padding: 0 16px;
  white-space: pre-wrap;
  text-align: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
  word-wrap: break-word;
  margin-top: 5%;
}

.header-media-area {
  padding: 3px 3px 0 3px;
}

.header-media-content {
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80px 80px;
  border-radius: 4px;
  box-sizing: border-box;
}

.header-media-content::after {
  content: '';
  display: block;
  padding-top: calc(100% * (1 / 1.91));
}

.header-media-image {
  background-image: url('https://media.wappin.chat/template-header-image.png');
}

.header-media-filled {
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  border-radius: 4px;
  box-sizing: border-box;
  /* width: 240px; */
}

.header-media-filled::after {
  content: '';
  display: block;
  padding-top: calc(100% * (1 / 1.91));
}

.metadata {
  display: inline-block;
  float: right;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -4px;
}

.metadata .time {
  color: rgba(0, 0, 0, .45);
  font-size: 11px;
  display: inline-block;
}

.metadata .tick {
  display: inline-block;
  margin-left: 2px;
  position: relative;
  top: 4px;
  height: 16px;
  width: 16px;
}

.metadata .tick svg {
  position: absolute;
  transition: .5s ease-in-out;
}

.metadata .tick svg:first-child {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(180deg);
          transform: perspective(800px) rotateY(180deg);
}

.metadata .tick svg:last-child {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(0deg);
          transform: perspective(800px) rotateY(0deg);
}

.metadata .tick-animation svg:first-child {
  -webkit-transform: perspective(800px) rotateY(0);
          transform: perspective(800px) rotateY(0);
}

.metadata .tick-animation svg:last-child {
  -webkit-transform: perspective(800px) rotateY(-179.9deg);
          transform: perspective(800px) rotateY(-179.9deg);
}

/* Compose */

.conversation-compose {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: hidden;
  height: 50px;
  width: 100%;
  z-index: 2;
}

.conversation-compose div,
.conversation-compose input {
  background: #fff;
  height: 100%;
}

.conversation-compose .emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 5px 0 0 5px;
  flex: 0 0 auto;
  margin-left: 8px;
  width: 48px;
}

.conversation-compose .input-msg {
  border: 0;
  flex: 1 1 auto;
  font-size: 16px;
  margin: 0;
  outline: none;
  min-width: 50px;
}

.conversation-compose .photo {
  flex: 0 0 auto;
  border-radius: 0 0 5px 0;
  text-align: center;
  position: relative;
  width: 48px;
}

.conversation-compose .photo:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  position: absolute;
  width: 0;
  height: 0;
  content: "";
  top: 0;
  right: -10px;
}

.conversation-compose .photo i {
  display: block;
  color: #7d8488;
  font-size: 24px;
  transform: translate(-50%, -50%);
  position: relative;
  top: 50%;
  left: 50%;
}

.conversation-compose .send {
  background: transparent;
  border: 0;
  cursor: pointer;
  flex: 0 0 auto;
  margin-left: 8px;
  margin-right: 8px;
  padding: 0;
  position: relative;
  outline: none;
}

.conversation-compose .send .circle {
  background: #008a7c;
  border-radius: 50%;
  color: #fff;
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conversation-compose .send .circle i {
  font-size: 24px;
  margin-left: 5px;
}

.preview-footer {
  font-size: 11px;
  color: rgba(0, 0, 0, .45);
  word-wrap: break-word;
  white-space: pre-wrap;
}

.preview-body {
  font-size: 12px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.row-button {
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
}

.col-button {
  background-color: #fff;
  border-radius: 7.5px;
  box-shadow: 0 1px 0.5px rgb(0 0 0 / 15%);
  box-sizing: border-box;
  flex-grow: 1;
  margin: 2px 0 0 2px;
  min-width: calc(50% - 2px);
  color: #00a5f4;
  font-size: 14px;
  height: 34px;
  line-height: 20px;
  padding: 0 16px;
  white-space: pre-wrap;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
}

.swiper-slide {
  display: flex !important;
  justify-content: flex-start !important;
  flex-direction: column !important;
  /* width: 202px !important; */
  /* width: 100% !important; */
  min-width: 274px;
}
.swiper-wrapper {
  display: flex;
  /* width: 980px !important; */
  min-width: 300px;
  width: auto;
}
.swiper-container {
  height: 450px !important;
  width: 100% !important;
}

/* Small Screens */
@media (max-width: 768px) {
  .marvel-device.nexus5 {
    border-radius: 0;
    flex: none;
    padding: 0;
    max-width: none;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }

  .marvel-device > .screen .chat {
    visibility: visible;
  }

  .marvel-device {
    visibility: hidden;
  }

  .marvel-device .status-bar {
    display: none;
  }

  .screen-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .conversation {
    height: calc(100vh - 55px);
  }
  .conversation .conversation-container {
    height: calc(100vh - 120px);
  }
}
</style>
